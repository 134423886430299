import {
  Container, Typography, Grid, useMediaQuery, useTheme, Box,
} from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import MemberCard from 'components/MemberCard';
import {
  CAREERS,
  CommitteeTeam,
  CONFERENCES,
  CREATIVE,
  CTF,
  CTF_AND_EDUCATION,
  EDUCATION,
  EVENTS,
  EVENTS_AND_SCONES,
  MARKETING,
  MEDIA,
  PROJECTS,
  SCONES,
  SOCIALS,
} from 'types/CommitteeMember';
import { PORTFOLIOS } from 'utils/config';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const carouselVariants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? '105vw' : '-105vw',
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? '105vw' : '-105vw',
      opacity: 0,
    };
  },
};

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 650px;

  @media screen and (max-width: 768px) {
    height: 850px;
  }

  @media screen and (max-width: 585px) {
    height: 1050px;
  }

  @media screen and (max-width: 500px) {
    height: 900px;
  }
`;

const PortfolioWrapper = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;

  & img {
    user-select: none;
  }
`;

const portfolioDescriptions = [
  {
    portfolio: EVENTS,
    description:
      'The Events portfolio is responsible for organising workshops and talks for society members as well as non-technical, social and wellbeing events.',
  },
  {
    portfolio: EVENTS_AND_SCONES,
    description:
      'The Events & Scones portfolio is responsible for organising workshops and talks for society members as well as non-technical, social and wellbeing events, including our Scones conference.',
  },
  {
    portfolio: SOCIALS,
    description:
      'The Socials portfolio is responsible for organising workshops and talks for society members as well as non-technical, social and wellbeing events.',
  },
  {
    portfolio: CTF,
    description:
      'The CTF portfolio is responsible for organising CTFs run internally and for coordinating the society’s involvement in external CTF competitions.',
  },
  {
    portfolio: CTF_AND_EDUCATION,
    description:
      'The CTF and Education portfolio is responsible for running workshops, organising CTFs run internally and for coordinating the society’s involvement in external CTF competitions.',
  },
  {
    portfolio: EDUCATION,
    description:
      'The Education portfolio is responsible for running workshops, study sessions and producing educational materials for the society.',
  },
  {
    portfolio: PROJECTS,
    description:
      'The Projects portfolio is responsible for the society’s infrastructure, website (unswsecurity.com) and projects.',
  },
  {
    portfolio: MEDIA,
    description:
      'The Media portfolio is responsible for designing, creating, and publishing media for the society.',
  },
  {
    portfolio: MARKETING,
    description:
      'The Marketing portfolio is responsible for organising and publishing media for the society.',
  },
  {
    portfolio: CREATIVE,
    description:
      'The Creative portfolio is responsible for designing and creating media for the society.',
  },
  {
    portfolio: CAREERS,
    description:
      'The Careers portfolio is responsible for running professional events in collaboration with sponsors for the society.',
  },
  {
    portfolio: SCONES,
    description:
      'The Scones portfolio is responsible for the organisation and logistics of the annual Student Cybersecurity Conference with SecEdu - SCONES for short!',
  },
  {
    portfolio: CONFERENCES,
    description:
      'The Conferences portfolio is responsible for the organisation and logistics of conferences including the annual Student Cybersecurity Conference with SecEdu - SCONES for short!',
  },
];

const ArrowWrapper = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
  z-index: 10;

  & > * {
    color: var(--black);
  }
  :hover {
    cursor: pointer;
  }
`;

const LeftArrowWrapper = styled(ArrowWrapper)`
  position: absolute;
  left: 5vw;
  top: 50%;
  transform: translateY(50%);
`;

const RightArrowWrapper = styled(ArrowWrapper)`
  position: absolute;
  right: 5vw;
  top: 50%;
  transform: translateY(50%);
`;

interface PortfolioSubcommitteeProps {
  committeeTeam: CommitteeTeam;
}

const PortfolioSubcommittee = ({ committeeTeam }: PortfolioSubcommitteeProps) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const ARROW_SIZE = 20;

  const [currentPortfolioIndex, setCurrentPortfolioIndex] = useState(0);
  const cyclePortfolio = useCallback(
    (direction: number) => {
      if (committeeTeam.committee) {
        let newIndex = currentPortfolioIndex;
        let first = true;
        // I don't like this loop, but I can't figure out a better way to satisfy eslint.
        while (first || newIndex !== currentPortfolioIndex) {
          first = false;
          // Go to the next portfolio.
          newIndex += direction;
          // Check for wraparound in either direction.
          if (newIndex === PORTFOLIOS.length) {
            newIndex = 0;
          } else if (newIndex === -1) {
            newIndex = PORTFOLIOS.length - 1;
          }

          // Saving newIndex so that eslint shuts up about using a loop variable in a closure.
          const i = newIndex;
          // Find a relevent portfolio member.
          const newCommittee = committeeTeam.committee.find(
            (member) => member.portfolio === PORTFOLIOS[i],
          );
          if (newCommittee !== undefined) {
            setCurrentPortfolioIndex(newIndex);
            return;
          }
        }
      }
    },
    [currentPortfolioIndex],
  );
  const currentPortfolio = {
    directors: committeeTeam.committee?.filter(
      (member) => member.portfolio === PORTFOLIOS[currentPortfolioIndex],
    ),
    subcommittee: committeeTeam.subcommittee?.filter(
      (member) => member.portfolio === PORTFOLIOS[currentPortfolioIndex],
    ),
    info: portfolioDescriptions.find(
      (description) => description.portfolio === PORTFOLIOS[currentPortfolioIndex],
    ),
  };

  const [direction, setDirection] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setDirection(1);
      cyclePortfolio(1);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [currentPortfolioIndex]);

  return (
    <>
      <Typography variant="h4" align="center">
        Portfolio
      </Typography>
      <CarouselWrapper>
        {desktop && (
          <>
            <LeftArrowWrapper
              onClick={() => {
                setDirection(-1);
                cyclePortfolio(-1);
              }}
            >
              <FaChevronLeft size={ARROW_SIZE} />
            </LeftArrowWrapper>
            <RightArrowWrapper
              onClick={() => {
                setDirection(1);
                cyclePortfolio(1);
              }}
            >
              <FaChevronRight size={ARROW_SIZE} />
            </RightArrowWrapper>
          </>
        )}
        <AnimatePresence initial={false} custom={direction}>
          <PortfolioWrapper
            key={currentPortfolioIndex}
            variants={carouselVariants}
            initial="enter"
            animate="center"
            custom={direction}
            exit="exit"
            transition={{
              x: { type: 'spring', stiffness: 250, damping: 30 },
              opacity: { duration: 1.5 },
            }}
          >
            <Container maxWidth="md">
              {currentPortfolio.info && (
                <>
                  <Typography variant="h5" align="center" className="highlight">
                    {currentPortfolio.info.portfolio}
                  </Typography>
                  <Typography variant="body1" align="center">
                    {currentPortfolio.info.description}
                  </Typography>
                </>
              )}

              <Grid container spacing={5} justifyContent="center">
                <Grid item md={6} sm={6} xs={12}>
                  <Typography variant="h5" className="highlight" align="center">
                    Directors
                  </Typography>
                  <Grid container spacing={2} justifyContent="center">
                    {currentPortfolio.directors
                      && currentPortfolio.directors.map((member) => (
                        <Grid
                          item
                          container
                          justifyContent="center"
                          key={member.name}
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <Box maxWidth={!desktop ? '350px' : '100%'}>
                            <MemberCard
                              name={member.name}
                              bio={member.bio}
                              role={member.role}
                              email={member.email}
                              slack={member.slack}
                              discord={member.discord}
                              website={member.website}
                            />
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
                {currentPortfolio.subcommittee && currentPortfolio.subcommittee.length > 0 && (
                  <Grid item md={6} sm={6} xs={12}>
                    <Typography variant="h5" className="highlight" align="center">
                      Subcommittee
                    </Typography>
                    {currentPortfolio.subcommittee.map((member) => (
                      <Typography variant="body2" align="center" key={member.name}>
                        {member.name}
                      </Typography>
                    ))}
                  </Grid>
                )}
              </Grid>
            </Container>
          </PortfolioWrapper>
        </AnimatePresence>
      </CarouselWrapper>
    </>
  );
};

export default PortfolioSubcommittee;
