import React from 'react';
import styled from 'styled-components';
import { Container, Typography } from '@material-ui/core';
import team from 'assets/images/secsoc/secsoc-team-2024.png';
import { Link } from 'gatsby';
import Button from 'components/Button';
import { CURR_TEAM_YEAR } from 'utils/config';

const CommitteeBlurbSection = styled.section`
  margin: var(--section-margin);
`;

const CommitteeImg = styled.img`
  width: 100%;
  padding-top: 50px;
`;

const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const CommitteeTitle = styled.div`
  margin: var(--section-margin);
`;

const CommitteeBlurb = () => {
  return (
    <CommitteeBlurbSection>
      <Container maxWidth="md">
        <Typography variant="body1" align="center">
          {`In ${CURR_TEAM_YEAR}`}
          , we are focused on making our events and workshops more accessible to people new
          to security. To help with this we've expanded our portfolios list to include a portfolio
          dedicated to running our SCONES security conference and an Education portfolio,
          focused on running workshops.
        </Typography>
        <BtnWrapper>
          <Link to="/about/previous-committee">
            <Button>See Previous Teams</Button>
          </Link>
        </BtnWrapper>
        <CommitteeTitle>
          <Typography variant="h3" align="center">
            {CURR_TEAM_YEAR}
            <span className="highlight">&nbsp;Committee Team</span>
          </Typography>
          <Typography variant="subtitle1" align="center">
            {`Meet the ${CURR_TEAM_YEAR} `}
            committee team of the UNSW Security Society that are responsible for this
            year's initiatives and events!
          </Typography>
          <CommitteeImg src={team} alt="2024 Society Team" width="100%" />
        </CommitteeTitle>
      </Container>
    </CommitteeBlurbSection>
  );
};

export default CommitteeBlurb;
